import React from 'react'
import closeIcon from "./img/close.png";

const MultiSelectOption = ({ 
  currentOption, 
  handleClickInDeleteOption,
}) => {
  return (
    <div className="multi-select__current-option d-flex px-2 text-white rounded align-items-center">
      {currentOption}
      <img
        className='multi-select__close-icon ml-2'
        src={closeIcon} 
        alt='close-icon' 
        width={10}
        height={10}
        onClick={(e) => handleClickInDeleteOption(e, currentOption)}
      />
    </div>
  );
}

export default MultiSelectOption