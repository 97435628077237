import classNames from 'classnames';
import React, { useState } from 'react'
import checkIcon from "./img/check.png";
import "./styles/MultiSelectOptions.scss";
import { useIntl } from 'react-intl';

const MultiSelectOptions = ({ selectValues, handleClickCurrentOption, selectedOptions }) => {
  const intl = useIntl();
  const [products, setProducts] = useState(selectValues);

  const handleProductFilter = (e) => {
    const filterValue = e.target.value.toLowerCase();

    if (!filterValue) {
      setProducts(selectValues);
      return;
    }
  
    const filteredProducts = selectValues.filter((product) =>
      product.label.toLowerCase().includes(filterValue)
    );
  
    setProducts(filteredProducts);
  };

  return (
    <div className="multi-options bg-white p-3 mt-1">
      <input 
        className='multi-options__search col-12 mb-2 px-1 border-top-0 border-left-0 border-right-0 border-bottom' 
        type='text' 
        alt='filter-products' 
        placeholder={intl.formatMessage({ id: "ProductsMultiSelect.searchProducts" })}
        onChange={handleProductFilter}
      />
      {products?.map(({ id, label }, index) => (
        <div
          key={id}
          className={classNames({
            "select__current-option d-flex align-items-center": true,
            "select__current-option-with-border":
              index !== products.length - 1,
          })}
          onClick={(e) => handleClickCurrentOption(e, label)}
        >
          <span
            className={classNames({
              "check-option border border-dark rounded-circle d-flex align-items-center justify-content-center p-2 mr-1": true,
              "check-option-selected": selectedOptions.includes(label),
            })}
            onClick={(e) => handleClickCurrentOption(e, label)}
          >
            <img src={checkIcon} alt="check-icon" width={10} height={10} />
          </span>
          {label}
        </div>
      ))}
    </div>
  );
}

export default MultiSelectOptions;
