import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';
import "./styles/SelectWithCustomOption.scss";
import { useIntl } from 'react-intl';

const SelectWithCustomOption = ({ 
  name, 
  selectValues, 
  placeholderMessage, 
  updateField,
  customOptionText
}) => {
  const intl = useIntl();
  const containerRef = useRef(null);
  const customInputRef = useRef(null);
  const [fisrtClick, setFirstClick] = useState(false);
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const handleShowDropdown = () => {
    setShowDropdown(!showDropdown);
    setFirstClick(true);
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    updateField(name, value);
    setFirstClick(false);
  };

  const handlePressEnterInput = (e) => {
    if (e.key === "Enter") {
      setShowDropdown(false);
      setShowCustomInput(false);
      setFirstClick(false);
    }
  };

  const handleContainerClick = () => {
    if (!fisrtClick) {
      handleShowDropdown();
    }
  };

  const handleOptionSelection = (e) => {
    if (e.target.innerText === customOptionText) {
      setShowCustomInput(true);
    } else {
      setSelectedValue(e.target.innerText);
      updateField(name, e.target.innerText);
      setShowDropdown(false);
      setFirstClick(false);
    }
  };

  const handleClickInOption = (e) => {
    if (!showCustomInput) {
      handleOptionSelection(e);
    }
  }

  useEffect(() => {
    if (showCustomInput && customInputRef.current) {
      customInputRef.current.focus(); /* Focus on custom input after do click in customOptionText */
    }
  }, [showCustomInput]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <Fragment>
      <div
        ref={containerRef}
        className="custom-select form-control bg-transparent col-12 position-relative"
        onClick={handleContainerClick}
      >
        <div className="select__default-option">
          {!selectedValue ? placeholderMessage : selectedValue}
        </div>
        {showDropdown && (
          <div className="application-options bg-white p-3 mt-1">
            {selectValues.map(({ id, label }, index) => (
              <div
                key={id}
                className={classNames({
                  "select__current-option": true,
                  "select__current-option-with-border":
                    index !== selectValues.length - 1,
                })}
                onClick={handleClickInOption}
              >
                {showCustomInput && label === customOptionText ? (
                  <Field
                    id={name}
                    type="text"
                    name={name}
                    className="select-input__custom bg-transparent col-12 border-0"
                    innerRef={customInputRef}
                    placeholder={intl.formatMessage({ id: "ApplicationSelect.whichOne" })}
                    onChange={handleChangeInput}
                    onKeyDown={handlePressEnterInput}
                  />
                ) : (
                  label
                )}
              </div>
            ))}
          </div>
        )}
        <Field
          id={name}
          name={name}
          component="select"
          className="select--field"
        />
      </div>
      <ErrorMessage
        className="form-error-message"
        component="div"
        name={name}
      />
    </Fragment>
  );
}

export default SelectWithCustomOption;