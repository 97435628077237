import { defineMessages } from "react-intl";

export const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "ProjectStepForm.field.required",
    description: "Project creation required field message",
    defaultMessage: "This field is required"
  }
})

export const PLACEHOLDER_MESSAGES = {
  salesRepresentative: {
    id: "ProjectStepForm.step2.field.salesRepresentative",
    description: "Placeholder to field salesRepresentative",
    defaultMessage: "Comercial que vendio el proyecto"
  },
  invoiceRecipient: {
    id: "ProjectStepForm.step2.field.invoiceRecipient",
    description: "Placeholder to field invoiceRecipient",
    defaultMessage: "A quién se le facturó el proyecto"
  },
  projectSpecifier: {
    id: "ProjectStepForm.step2.field.projectSpecifier",
    description: "Placeholder to field projectSpecifier",
    defaultMessage: "Quién especificó el proyecto"
  },
  projectManufacturer: {
    id: "ProjectStepForm.step2.field.projectManufacturer",
    description: "Placeholder to field projectManufacturer",
    defaultMessage: "Quién manufacturó el proyecto"
  },
  finalClient: {
    id: "ProjectStepForm.step2.field.finalClient",
    description: "Placeholder to field finalClient",
    defaultMessage: "Cliente final"
  },
  soldMeters: {
    id: "ProjectStepForm.step2.field.soldMeters",
    description: "Placeholder to field soldMeters",
    defaultMessage: "Metros vendidos"
  }
}