import React from "react";
import StepInfo from "../../StepInfo";
import { FormattedMessage } from "react-intl";
import StepGuideNumbers from "../../StepGuideNumbers";
import Step0Form from "./components/Step0Form";
import useMediaQuery from "utils/hooks/useMediaQuery";
import "./styles/Step0.scss";

const Step0 = () => {
  const isMobile = useMediaQuery("(max-width: 992px)");
  const progressStepValue = 25;
  const currentStep = [0];

  return (
    <main title="step-0-form" className="step0--main">
      <StepInfo
        subtitle={
          <FormattedMessage
            id="ProjectStepForm.step0.subtitle"
            defaultMessage="¡Bienvenido al sistema de la Biblioteca de Proyectos!"
          />
        }
        additionalInfo={
          <FormattedMessage
            id="ProjectStepForm.step0.additionalInfo"
            defaultMessage="Diligenciando el siguiente formulario, podrás cargar el tuyo."
          />
        }
        description={
          <FormattedMessage
            id="ProjectStepForm.step0.description"
            defaultMessage="El proyecto estará sujeto a aprobación del área de Mercadeo del negocio y de Mercadeo Central."
          />
        }
      />
      {isMobile && (
        <StepGuideNumbers
          currentStep={currentStep}
          progressStepValue={progressStepValue}
          isMobile={isMobile}
        />
      )}
      <section title="section-wizard-form" className="step0--main__wizard-form">
        {!isMobile && <StepGuideNumbers currentStep={currentStep} />}
        <div className="step0--main__wizard-container bg-light col-12 col-lg-8 p-3 p-lg-5">
          <progress max={100} value={progressStepValue} className="w-100" />
          <h5 className="step0--main__form-title mt-4 font-weight-bold">
            <FormattedMessage
              id="ProjectStepForm.step0.formTitle"
              defaultMessage="¿Quién realiza la solicitud de publicación del nuevo proyecto?"
            />
          </h5>
          <hr />
          <Step0Form />
        </div>
      </section>
    </main>
  );
};

export default Step0;
