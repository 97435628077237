import { ErrorMessage, Field, Form, Formik } from 'formik';
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { BUSINESS_UNIT_VALUES } from '../../../Step0/components/Step0Form/constants/select-values';
import { PLACEHOLDER_MESSAGES } from '../../../Step0/components/Step0Form/constants';
import CustomSelect from 'scenes/ProjectMultiStepForm/components/CustomSelect';
import { useStateMachine } from 'little-state-machine';
import { updateAction } from 'scenes/ProjectMultiStepForm/store';
import { PLACEHOLDER_MESSAGES_STEP1 } from './constants';
import { getValidationShema } from './constants/validationSchema';
import { withRouter } from 'react-router';
import ProductsSelect from './components/ProductsSelect';
import ApplicationSelect from './components/ApplicationSelect';
import SectorSelect from './components/SectorSelect';
import SegmentSelect from './components/SegmentSelect';
import TooltipField from 'scenes/ProjectMultiStepForm/components/TooltipField';
import useCapitalizedLetters from 'scenes/ProjectMultiStepForm/hooks/useCapitalizedLetters';

const Step1Form = ({ history }) => {
  const intl = useIntl();
  const { state, actions } = useStateMachine({ updateAction });
  const { capitalizedFields, handleApplyCapitalizedLetters } = useCapitalizedLetters();

  const validationCapitalizedFields = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    handleApplyCapitalizedLetters(name, value);
  }

  const initialValues = {
    businessUnit: "",
    projectName: "",
    products: "",
    sector: "",
    segment: "",
    application: "",
    countryProject: capitalizedFields?.countryProject || "",
    cityProject: capitalizedFields?.cityProject || "",
    implementationYear: ""
  }
  
  const handleSubmit = (stepData) => {
    actions.updateAction({ ...state, stepData, stepNumber: 1 });
    /* Go to next step */
    history.push("/project/wizard-form/step2");
  };
  
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationShema(intl)}
    >
      {({ setFieldValue, values: { sector }, isSubmitting }) => {
        return <Form id="step-1-form">
          <div className="step-0-form__container d-flex flex-column">
            <section className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
              <label
                className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                htmlFor="businessUnit"
              >
                <FormattedMessage
                  id="ProjectStepForm.step1.field.businessUnit"
                  defaultMessage="Unidad de negocio que gestionó el proyecto"
                />
                {"*"}
              </label>
              <CustomSelect
                name={"businessUnit"}
                updateField={setFieldValue}
                placeholderMessage={intl.formatMessage(PLACEHOLDER_MESSAGES.businessUnit)}
                selectValues={BUSINESS_UNIT_VALUES}
              />
            </section>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="projectName"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.projectName"
                    defaultMessage="Nombre del proyecto"
                  />
                  {"*"}
                </label>
                <Field
                  id="projectName"
                  name="projectName"
                  className="form-control bg-transparent"
                  type="text"
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES_STEP1.projectName)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="projectName"
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="products"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.products"
                    defaultMessage="Producto(s) usado(s) en el proyecto"
                  />
                  {"*"}
                </label>
                <TooltipField 
                  tooltipMessage={(
                    <FormattedMessage 
                      id="ProjectStepForm.tooltip.products"
                      defaultMessage='Elige la referencia exacta del producto que fue usada en el proyecto. Ej. Valencia, Valencia C5, Valencia C5 Heritage o Valencia Biosense'
                    />
                  )} 
                />
                <ProductsSelect name="products" updateField={setFieldValue} />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="sector"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.sector"
                    defaultMessage="Sector/Mercado"
                  />
                  {"*"}
                </label>
                <SectorSelect name="sector" updateField={setFieldValue} />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="segment"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.segment"
                    defaultMessage="Segmento"
                  />
                  {"*"}
                </label>
                <SegmentSelect name="segment" updateField={setFieldValue} sectorValue={sector} />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="application"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.finalApplication"
                    defaultMessage="Uso final"
                  />
                </label>
                <TooltipField 
                  tooltipMessage={(
                    <FormattedMessage 
                      id="ProjectStepForm.tooltip.application"
                      defaultMessage='Especifica dónde fue usado/aplicado el producto Ej: Mobiliario, yate, avión, obra de arte.'
                    />
                  )} 
                />
                <ApplicationSelect name="application" updateField={setFieldValue} />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="countryProject"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.countryProject"
                    defaultMessage="País donde está el proyecto"
                  />
                  {"*"}
                </label>
                <Field
                  id="countryProject"
                  name="countryProject"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.countryProject}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES_STEP1.countryProject)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="countryProject"
                />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="cityProject"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.cityProject"
                    defaultMessage="Ciudad donde esta el proyecto"
                  />
                </label>
                <Field
                  id="cityProject"
                  name="cityProject"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.cityProject}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES_STEP1.cityProject)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="cityProject"
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="implementationYear"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step1.field.implementationYear"
                    defaultMessage="Año de implementación del proyecto"
                  />
                </label>
                <Field
                  id="implementationYear"
                  name="implementationYear"
                  className="form-control bg-transparent"
                  type="number"
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES_STEP1.implementationYear)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="implementationYear"
                />
              </div>
            </div>
            <button
              type="submit"
              className="step-0-form__submit-btn px-5 py-2 mt-4"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="ProjectStepForm.button.continue"
                defaultMessage="Continuar"
              />
            </button>
          </div>
        </Form>
      }}
    </Formik>
  );
}

export default withRouter(Step1Form);