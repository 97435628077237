import React from "react";
import { FormattedMessage } from "react-intl";


/* Guide numbers Steps */
export const GUIDE_STEPS = [
  {
    id: 1,
    number: 0,
    step: (
      <FormattedMessage 
        id="ProjectStepForm.step0.title"
        defaultMessage="Quién realiza la solicitud"
      />
    )
  },
  {
    id: 2,
    number: 1,
    step: (
      <FormattedMessage 
        id="ProjectStepForm.step1.title"
        defaultMessage="Datos del proyecto"
      />
    )
  },
  {
    id: 3,
    number: 2,
    step: (
      <FormattedMessage 
        id="ProjectStepForm.step2.title"
        defaultMessage="Datos de facturación"
      />
    )
  },
  {
    id: 4,
    number: 3,
    step: (
      <FormattedMessage 
        id="ProjectStepForm.step3.title"
        defaultMessage="Datos de autorización de uso"
      />
    )
  },
  {
    id: 5,
    number: 4,
    step: (
      <FormattedMessage 
        id="ProjectStepForm.step4.title"
        defaultMessage="Fotos del proyecto"
      />
    )
  }
]