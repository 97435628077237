import classNames from 'classnames';
import React from 'react'
import { LG } from 'scenes/ProductDetails/components/ProductSpecs/components/OtherAttributesAccordion/constants/breakpoints';
import useMediaQuery from 'utils/hooks/useMediaQuery';

const DesktopProgress = ({ isActive, isLastStep }) => {
  const isMobile = useMediaQuery(LG);

  return (
    !isMobile && !isLastStep && (
      <div className="position-relative">
        <div className={classNames({
          "below_divider": true,
          "step-active": isActive,
        })}></div>
      </div>
    )
  )
}

export default DesktopProgress;