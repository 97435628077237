import React from 'react'
import { PLACEHOLDER_MESSAGES_STEP1 } from '../../constants';
import { getApplicationValues } from './constants/applications';
import { useIntl } from 'react-intl';
import SelectWithCustomInput from './components/SelectWithCustomInput';

const ApplicationSelect = ({ name, updateField }) => {
  const intl = useIntl();

  return (
    <SelectWithCustomInput
      name={name}
      updateField={updateField}
      placeholderMessage={intl.formatMessage(
        PLACEHOLDER_MESSAGES_STEP1["application"]
      )}
      selectValues={getApplicationValues(intl)}
      customOptionText="Otro"
    />
  );
}

export default ApplicationSelect;