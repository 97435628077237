import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import "./styles/HomeOurProducts.scss";
import { Link } from "react-router-dom";

const NewHomeLibrary = () => {
  return (
    <section className="home-top-section">
      <div className="row no-gutters">
        <div className="home-our-products col-12 col-md-6" />
        <div className="col-12 col-md-6 d-flex justify-content-center home-container-section-description">
          <div className="container section-container-description">
            <h3 className="col-12 text-secondary home-our-products__title p-0">
              <FormattedMessage
                id="Home.products.title"
                defaultMessage="Our products"
              />
              {/* WARNING: This button is for development purposes of STEPFORM PROJECTS */}
              <Link to={"project/wizard-form"}>
                <Button className="font-weight-bold home-button-more-info mx-auto py-2 px-md-5">
                  <FormattedMessage
                    id="Projects.Home.btns"
                    defaultMessage="Go to stepform"
                  />
                </Button>
              </Link>
            </h3>
            <p className="col-md-10 mt-3 home-description-our-products text-secondary p-0">
              <FormattedMessage
                id="Home.products.description"
                defaultMessage="Descubre nuestro catálogo de productos con información detallada sobre características {br}y atributos."
                values={{
                  br: <br />,
                }}
              />
            </p>
            <div className="text-center mt-5">
              <a href="/products">
                <Button className="font-weight-bold home-button-more-info py-1 px-md-5 mx-auto home--project-library_btn">
                  <FormattedMessage
                    id="Home.products.btn"
                    defaultMessage="View products"
                  />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row no-gutters">
        <div className="col-12 col-md-6 d-flex justify-content-center home-container-section-description order-2 order-md-1">
          <div className="container section-container-description">
            <h3 className="col-12 text-secondary home-our-products__title p-0">
              <FormattedMessage
                id="Home.projects.title"
                defaultMessage="Our projects"
              />
            </h3>
            <p className="col-12 p-0 mt-3 home-description-our-products text-secondary">
              <FormattedMessage
                id="Home.projects.description"
                defaultMessage="Explore <strong>Spradling<sup>®</sup>'s</strong> projects done for top clients around the world."
                values={{
                  strong: (...chunks) => (
                    <strong className="font-weight-bold">{chunks}</strong>
                  ),
                  sup: (...chunks) => <sup>{chunks}</sup>,
                }}
              />
            </p>
            <div className="text-center mt-5">
              <a href="/projects">
                <Button className="font-weight-bold home-button-more-info py-1 px-md-5 mx-auto home--project-library_btn">
                  <FormattedMessage
                    id="Home.projects.btn"
                    defaultMessage="View projects"
                  />
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div className="home-our-projects col-12 col-md-6 order-1 order-md-2" />
      </div>
    </section>
  );
};

export default NewHomeLibrary;
