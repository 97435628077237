import React from "react";
import "./styles/StepGuideNumbers.scss";
import { GUIDE_STEPS } from "./constants";
import classNames from "classnames";
import MobileProgress from "./components/StepGuideMobileProgress";
import DesktopProgress from "./components/StepGuideDesktopProgress";

const StepGuideNumbers = ({ currentStep, isMobile, progressStepValue }) => {
  return (
    <div className="step0--main__step-guide p-lg-5">
      <div className={classNames({
        "d-flex position-relative": true,
        "flex-column": !isMobile,
        "justify-content-between": isMobile,
        })}>
        <MobileProgress progressValue={progressStepValue} />
        {GUIDE_STEPS.map((guideStep, index) => {
          const { number, id, step } = guideStep;
          return (
            <div
              key={`key__step-guide-${id}`}
              className="d-flex align-items-center step0--main__current-step"
            >
              <DesktopProgress
                isLastStep={index === GUIDE_STEPS.length - 1}
                isActive={currentStep.includes(number)}
              />
              <span
                className={classNames({
                  "step-active": currentStep.includes(number),
                  "step0--main__guide-number": true,
                  "mr-3 relative": !isMobile,
                  "rounded-circle d-flex align-items-center justify-content-center": true,
                })}>
                {number}
              </span>
              {!isMobile && <h6 className="step0--main__step-title font-weight-bold m-0 p-0">{step}</h6>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StepGuideNumbers;
