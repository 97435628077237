import { VALIDATION_MESSAGES } from "scenes/ProjectMultiStepForm/components/StepForm/Step0/components/Step0Form/constants";
import * as Yup from "yup";

/* Yup validation schema for step1 */
export const getValidationShema = (intl) => {
  const validationShema = Yup.object({
    businessUnit: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    projectName: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    products: Yup.array()
      .of(Yup.string())
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    sector: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    segment: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    application: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    countryProject: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    cityProject: Yup.string(),
    implementationYear: Yup.string()
  });

  return validationShema;
}
