import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';
import "./styles/MultiSelect.scss";
import MultiSelectOption from './components/MultiSelectOption';
import { FormattedMessage, useIntl } from 'react-intl';
import MultiSelectOptions from './components/MultiSelectOptions';

const MultiSelect = ({ 
  name, 
  selectValues,
  placeholderMessage,
  updateField,
  disabled = false
}) => {
  const containerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleShowDropdown = () => {
    if (!disabled) setShowDropdown(true);
  };

  const handleClickCurrentOption = (e, currentValue) => {
    e.stopPropagation();

    let updatedOptions;
  
    if (selectedOptions.includes(currentValue)) {
      updatedOptions = selectedOptions.filter(option => option !== currentValue);
    } else {
      updatedOptions = [...selectedOptions, currentValue];
    }
    
    setSelectedOptions(updatedOptions);
    updateField(name, updatedOptions);
  };

  const handleDeleteOption = (e, currentOption) => {
    e.stopPropagation();

    const optionsWithoutSelectedOption = selectedOptions.filter(
      (option) => option !== currentOption
    );
    setSelectedOptions(optionsWithoutSelectedOption);
    updateField(name, [...selectedOptions]);
  }

  useEffect(() => {
      const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
          setShowDropdown(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
  return (
    <Fragment>
      <div
        ref={containerRef}
        className={classNames({
          "bg-transparent": !disabled,
          "disabled-select": disabled,
          "custom-select multi-select form-control position-relative": true,
        })}
        onClick={handleShowDropdown}
      >
        <div className="multi-select__default-option d-flex">
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option, index) => (
              <MultiSelectOption
                key={index}
                currentOption={option}
                handleClickInDeleteOption={handleDeleteOption}
              />
            ))
          ) : (
            <span className="multi-select__placeholder">
              {disabled
                ? (
                  <FormattedMessage 
                    id="ProjectStepForm.loading.products"
                    defaultMessage='Cargando productos...'
                  />
                )
                : placeholderMessage}
            </span>
          )}
        </div>
        {showDropdown && selectValues && (
          <MultiSelectOptions
            selectedOptions={selectedOptions}
            selectValues={selectValues}
            handleClickCurrentOption={handleClickCurrentOption}
          />
        )}
        <Field
          id={name}
          name={name}
          component="select"
          className="select--field"
        />
      </div>
      <ErrorMessage
        className="form-error-message"
        component="div"
        name={name}
      />
    </Fragment>
  );
}

export default MultiSelect;