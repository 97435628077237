import React from "react";
import { FormattedMessage } from "react-intl"

/* Business Unit select values */
export const BUSINESS_UNIT_VALUES = [
  {
    id: 1,
    label: "CALYPSO",
    value: "CALYPSO",
  },
  {
    id: 2,
    label: "EUROPA",
    value: "EUROPA",
  },
  {
    id: 3,
    label: "LATAM",
    value: "LATAM",
  },
  {
    id: 4,
    label: "USA",
    value: "USA",
  }
]

/* Area select Values */
export const AREA_VALUES = [
  {
    id: 1,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step0.field.area.comercial"
        defaultMessage="Comercial"
      />
    ),
    value: "Comercial",
  },
  {
    id: 2,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step0.field.area.marketing"
        defaultMessage="Mercadeo"
      />
    ),
    value: "Mercadeo",
  },
  {
    id: 3,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step0.field.area.centrralMarketing"
        defaultMessage="Mercadeo Central"
      />
    ),
    value: "Mercadeo Central",
  }
]