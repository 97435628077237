import React from 'react';
import { withRouter } from 'react-router';
import backIcon from "./img/lineicons_chevron-up.svg";
import "./styles/StepNavigationBack.scss";
import { FormattedMessage } from 'react-intl';

const StepNavigationBack = ({ history }) => {

  const handleClickOnBack = () => history.goBack();

  return (
    <div className="my-3 d-flex align-items-center">
      <span className='step-navigation__back-button' onClick={handleClickOnBack}>
        <img src={backIcon} alt='back-icon' width={24} height={24} />
        <FormattedMessage 
          id="ProjectStepForm.stepform.goBack"
          description="Button go back stepform"
          defaultMessage="Anterior"
        />
      </span>
    </div>
  );
};

export default withRouter(StepNavigationBack);