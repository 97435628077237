import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getValidationShema,
} from "./constants/validationSchema";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PLACEHOLDER_MESSAGES } from "./constants";
import "./styles/Step2Form.scss";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "scenes/ProjectMultiStepForm/store";
import { withRouter } from "react-router";
import TooltipField from "scenes/ProjectMultiStepForm/components/TooltipField";
import { AUTHORIZATION_IMAGES_VALUES, AUTHORIZATION_PROJECT_VALUES } from "./constants/select-values";
import CustomSelect from "scenes/ProjectMultiStepForm/components/CustomSelect";
import CustomFileInput from "./components/CustomFileInput";
import useCapitalizedLetters from "scenes/ProjectMultiStepForm/hooks/useCapitalizedLetters";

const Step3Form = ({ history }) => {
  const intl = useIntl();
  const { state, actions } = useStateMachine({ updateAction });
  const { capitalizedFields, handleApplyCapitalizedLetters } = useCapitalizedLetters();

  const validationCapitalizedFields = (e, setFieldValue) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    handleApplyCapitalizedLetters(name, value);
  }

  const initialValues = {
    authorizationImages: state?.stepData?.authorizationImages || "",
    authorizationProject: state?.stepData?.authorizationProject || "",
    authorizationSupport: state?.stepData?.authorizationSupport || "",
    credits: state?.stepData?.credits || "",
    bussinessUnitPreviewAuthorization: state?.stepData?.bussinessUnitPreviewAuthorization || ""
  }
  
  const handleSubmit = (stepData) => {
    actions.updateAction({ stepData, stepNumber: 3 });
    /* Go to next step */
    history.push("/project/wizard-form/step4");
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationShema(intl)}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form id="step-0-form">
          <div className="step-0-form__container d-flex flex-column">
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="authorizationImages"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step3.field.authorizationImages"
                    defaultMessage="Autorización de uso de las imágenes"
                  />
                  {"*"}
                </label>
                <TooltipField tooltipMessage={(
                  <FormattedMessage 
                    id="ProjectStepForm.step3.field.tooltip.authorizationImages"
                    defaultMessage="Elije la autorización de uso de la lista desplegable. Si eliges uso restringido, es necesario solicitar permiso al área de mercadeo de la unidad de negocio."
                  />
                )} />
                <CustomSelect
                  name={"authorizationImages"}
                  updateField={setFieldValue}
                  placeholderMessage={intl.formatMessage(
                    PLACEHOLDER_MESSAGES.authorizationImages
                  )}
                  selectValues={AUTHORIZATION_IMAGES_VALUES}
                />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="authorizationProject"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step3.field.authorizationProject"
                    defaultMessage="Proyecto autorizado por el cliente"
                  />
                  {"*"}
                </label>
                <CustomSelect
                  name={"authorizationProject"}
                  updateField={setFieldValue}
                  placeholderMessage={intl.formatMessage(
                    PLACEHOLDER_MESSAGES.authorizationProject
                  )}
                  selectValues={AUTHORIZATION_PROJECT_VALUES}
                />
              </div>
            </div>
            <CustomFileInput />
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold d-inline"
                  htmlFor="credits"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step3.field.credits"
                    defaultMessage="Créditos en pie de foto que deben acompañar la imagen"
                  />
                </label>
                <TooltipField tooltipMessage={(
                  <FormattedMessage 
                    id="ProjectStepForm.step3.field.tooltip.credits"
                    defaultMessage="Indica el texto que debe acompañar la foto para indicar el fotógrafo o titular de los derechos."
                  />
                )} />
                <Field
                  id="credits"
                  name="credits"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.credits}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.credits)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="credits"
                />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="businessUnit"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step3.field.bussinessUnitPreviewAuthorization"
                    defaultMessage="Declaración previa de autorización de la Unidad de Negocio"
                  />
                </label>
                <Field
                  id="bussinessUnitPreviewAuthorization"
                  name="bussinessUnitPreviewAuthorization"
                  className="form-control bg-transparent"
                  type="text"
                  placeholder={intl.formatMessage(
                    PLACEHOLDER_MESSAGES.bussinessUnitPreviewAuthorization
                  )}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="bussinessUnitPreviewAuthorization"
                />
              </div>
            </div>
            <button
              type="submit"
              className="step-0-form__submit-btn px-5 py-2 mt-4"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="ProjectStepForm.button.continue"
                defaultMessage="Continuar"
              />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(Step3Form);
