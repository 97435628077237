import React from "react";
import StepInfo from "../../StepInfo";
import { FormattedMessage } from "react-intl";
import StepGuideNumbers from "../../StepGuideNumbers";
import Step2Form from "./components/Step2Form";
import useMediaQuery from "utils/hooks/useMediaQuery";
import "./styles/Step2.scss";
import StepNavigationBack from "../../StepNavigationBack";

const FinalStep = () => {
  const isMobile = useMediaQuery("(max-width: 992px)");
  const progressStepValue = 50;
  const currentStep = [0, 1, 2];

  return (
    <main title="step-0-form" className="step0--main">
      <StepInfo
        subtitle={
          <FormattedMessage
            id="ProjectStepForm.step1.subtitle"
            description="Subtitle of Step form 1"
            defaultMessage="Formulario | Biblioteca de Proyectos"
          />
        }
      />
      {isMobile && (
        <StepGuideNumbers
          currentStep={currentStep}
          progressStepValue={progressStepValue}
          isMobile={isMobile}
        />
      )}
      <section title="section-wizard-form" className="step0--main__wizard-form">
        {!isMobile && <StepGuideNumbers currentStep={currentStep} />}
        <div className="step0--main__wizard-container bg-light col-12 col-lg-8 p-3 p-lg-5">
          <progress max={100} value={progressStepValue} className="w-100" />
          <StepNavigationBack />
          STEP FINALLLLL
          <h5 className="step0--main__form-title mt-4 font-weight-bold">
            <FormattedMessage
              id="ProjectStepForm.step2.title"
              defaultMessage="Datos de facturacion"
            />
          </h5>
          <hr />
          <Step2Form />
        </div>
      </section>
    </main>
  );
};

export default FinalStep;
