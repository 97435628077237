import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getValidationShema,
} from "./constants/validationSchema";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PLACEHOLDER_MESSAGES } from "./constants";
import "./styles/Step0Form.scss";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "scenes/ProjectMultiStepForm/store";
import CustomSelect from "scenes/ProjectMultiStepForm/components/CustomSelect";
import { AREA_VALUES, BUSINESS_UNIT_VALUES } from "./constants/select-values";
import { withRouter } from "react-router";

const Step0Form = ({ history }) => {
  const intl = useIntl();
  const { state, actions } = useStateMachine({ updateAction });

  const initialValues = {
    fullname: state?.stepData?.fullname || "",
    email: state?.stepData?.email || "",
    businessUnit: state?.stepData?.businessUnit || "",
    area: state?.stepData?.area || ""
  }
  
  const handleSubmit = (stepData) => {
    actions.updateAction({ stepData, stepNumber: 0 });
    /* Go to next step */
    history.push("/project/wizard-form/step1");
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationShema(intl)}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form id="step-0-form">
          <div className="step-0-form__container d-flex flex-column">
            <section className="d-flex flex-column">
              <label
                className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                htmlFor="fullname"
              >
                <FormattedMessage
                  id="ProjectStepForm.step0.field.completeName"
                  defaultMessage="Tu nombre y apellido"
                />
                {"*"}
              </label>
              <Field
                id="fullname"
                name="fullname"
                className="form-control bg-transparent"
                type="text"
                placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.fullname)}
              />
              <ErrorMessage
                className="form-error-message"
                component="div"
                name="fullname"
              />
            </section>
            <section className="d-flex flex-column">
              <label
                className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
                htmlFor="email"
              >
                <FormattedMessage
                  id="ProjectStepForm.step0.field.email"
                  defaultMessage="Email"
                />
                {"*"}
              </label>
              <Field
                id="email"
                name="email"
                className="form-control bg-transparent"
                type="text"
                placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.email)}
              />
              <ErrorMessage
                className="form-error-message"
                component="div"
                name="email"
              />
            </section>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="businessUnit"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step0.field.businessUnit"
                    defaultMessage="Unidad de negocio a la que perteneces"
                  />
                  {"*"}
                </label>
                <CustomSelect
                  name={"businessUnit"}
                  updateField={setFieldValue}
                  placeholderMessage={intl.formatMessage(PLACEHOLDER_MESSAGES.businessUnit)}
                  selectValues={BUSINESS_UNIT_VALUES}
                  initialValue={state?.stepData?.businessUnit}
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="area"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step0.field.area"
                    defaultMessage="Área"
                  />
                  {"*"}
                </label>
                <CustomSelect
                  name={"area"}
                  updateField={setFieldValue}
                  placeholderMessage={intl.formatMessage(PLACEHOLDER_MESSAGES.area)}
                  selectValues={AREA_VALUES}
                  initialValue={state?.stepData?.area}
                />
              </div>
            </div>
            <button
              type="submit"
              className="step-0-form__submit-btn px-5 py-2 mt-4"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="ProjectStepForm.button.continue"
                defaultMessage="Continuar"
              />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(Step0Form);
