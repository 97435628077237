import { defineMessages } from "react-intl";

export const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "ProjectStepForm.field.required",
    description: "Project creation required field message",
    defaultMessage: "This field is required"
  }
})

export const PLACEHOLDER_MESSAGES = {
  authorizationImages: {
    id: "ProjectStepForm.step3.field.authorizationImages",
    description: "Placeholder to field authorizationImages",
    defaultMessage: "Autorización de uso de las imágenes"
  },
  authorizationProject: {
    id: "ProjectStepForm.step3.field.authorizationProject",
    description: "Placeholder to field authorizationProject",
    defaultMessage: "Proyecto autorizado por el cliente"
  },
  authorizationSupport: {
    id: "ProjectStepForm.step3.field.authorizationSupport",
    description: "Placeholder to field authorizationSupport",
    defaultMessage: "Carta, imagen, contrato o soporte de autorización de uso"
  },
  credits: {
    id: "ProjectStepForm.step3.field.credits",
    description: "Placeholder to field credits",
    defaultMessage: "Créditos en pie de foto que deben acompañar la imagen"
  },
  bussinessUnitPreviewAuthorization: {
    id: "ProjectStepForm.step3.field.bussinessUnitPreviewAuthorization",
    description: "Placeholder to field bussinessUnitPreviewAuthorization",
    defaultMessage: "Declaración previa de autorización de la Unidad de Negocio "
  },
}