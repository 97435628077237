import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { ErrorMessage, Field } from 'formik';
import classNames from 'classnames';
import "./styles/CustomSelect.scss";

const CustomSelect = ({ 
  name, 
  selectValues,
  placeholderMessage, 
  updateField,
  disabled = false,
  initialValue,
  disabledPlaceholder = ""
}) => {
  const containerRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(initialValue || '');
  const [selectedValueColor, setSelectedValueColor] = useState("");
  
  const handleShowDropdown = () => {
    if (!disabled) setShowDropdown(!showDropdown);
  };
  
  const handleClickCurrentOption = (e, color) => {
    setSelectedValueColor(color);
    setSelectedValue(e.target.innerText);
    updateField(name, e.target.innerText);
    setShowDropdown(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  return (
    <Fragment>
      <div
        ref={containerRef}
        className={classNames({
          "bg-transparent": !disabled,
          "disabled-select": disabled,
          "custom-select form-control col-12 position-relative": true
        })}
        onClick={handleShowDropdown}
      >
        <div className={classNames({
            "select__default-option": true,
            [selectedValueColor]: true,
          })}>
          {!selectedValue
            ? !disabled 
              ? placeholderMessage 
              : disabledPlaceholder
            : selectedValue}
        </div>
        {showDropdown && <div className="options bg-white p-3 mt-1">
          {selectValues?.map(({ id, label, color = "" }, index) => (
            <div
              key={id}
              className={classNames({
                'select__current-option': true,
                'select__current-option-with-border': index !== selectValues.length-1,
                [color]: true,
              })}
              onClick={(e) => handleClickCurrentOption(e, color)}
            >
              {label}
            </div>
          ))}
        </div>}
        <Field
          id={name}
          name={name}
          component="select"
          className="select--field"
          value={selectedValue}
        />
      </div>
      <ErrorMessage
        className="form-error-message"
        component="div"
        name={name}
      />
    </Fragment>
  );
}

export default CustomSelect;