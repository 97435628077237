export const getApplicationValues = (intl) => [
  {
    id: 1,
    value: intl.formatMessage({
      id: "ApplicationSelect.airplane",
      defaultMessage: "Avión",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.airplane",
      defaultMessage: "Avión",
    }),
  },
  {
    id: 2,
    value: intl.formatMessage({
      id: "ApplicationSelect.clothing",
      defaultMessage: "Vestuario",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.clothing",
      defaultMessage: "Vestuario",
    }),
  },
  {
    id: 3,
    value: intl.formatMessage({
      id: "ApplicationSelect.bank",
      defaultMessage: "Banco",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.bank",
      defaultMessage: "Banco",
    }),
  },
  {
    id: 4,
    value: intl.formatMessage({
      id: "ApplicationSelect.bed",
      defaultMessage: "Cama",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.bed",
      defaultMessage: "Cama",
    }),
  },
  {
    id: 5,
    value: intl.formatMessage({
      id: "ApplicationSelect.boat",
      defaultMessage: "Barco",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.boat",
      defaultMessage: "Barco",
    }),
  },
  {
    id: 6,
    value: intl.formatMessage({
      id: "ApplicationSelect.bus",
      defaultMessage: "Bus/Autobus",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.bus",
      defaultMessage: "Bus/Autobus",
    }),
  },
  {
    id: 7,
    value: intl.formatMessage({
      id: "ApplicationSelect.car",
      defaultMessage: "Carro/Automovil",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.car",
      defaultMessage: "Carro/Automovil",
    }),
  },
  {
    id: 8,
    value: intl.formatMessage({
      id: "ApplicationSelect.floors",
      defaultMessage: "Pisos",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.floors",
      defaultMessage: "Pisos",
    }),
  },
  {
    id: 9,
    value: intl.formatMessage({
      id: "ApplicationSelect.furniture",
      defaultMessage: "Muebles/Mobiliario (sillas, sofás, cabeceros, etc.)",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.furniture",
      defaultMessage: "Muebles/Mobiliario (sillas, sofás, cabeceros, etc.)",
    }),
  },
  {
    id: 10,
    value: intl.formatMessage({
      id: "ApplicationSelect.bags",
      defaultMessage: "Bolsos",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.bags",
      defaultMessage: "Bolsos",
    }),
  },
  {
    id: 11,
    value: intl.formatMessage({
      id: "ApplicationSelect.upholsteryInteriorExterior",
      defaultMessage: "Tapicería Interior y Exterior",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.upholsteryInteriorExterior",
      defaultMessage: "Tapicería Interior y Exterior",
    }),
  },
  {
    id: 12,
    value: intl.formatMessage({
      id: "ApplicationSelect.upholsteryInterior",
      defaultMessage: "Tapicería Interior",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.upholsteryInterior",
      defaultMessage: "Tapicería Interior",
    }),
  },
  {
    id: 13,
    value: intl.formatMessage({
      id: "ApplicationSelect.leathergoods",
      defaultMessage: "Marroquinería",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.leathergoods",
      defaultMessage: "Marroquinería",
    }),
  },
  {
    id: 14,
    value: intl.formatMessage({
      id: "ApplicationSelect.massageTables",
      defaultMessage: "Camillas de masaje",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.massageTables",
      defaultMessage: "Camillas de masaje",
    }),
  },
  {
    id: 15,
    value: intl.formatMessage({
      id: "ApplicationSelect.mattresses",
      defaultMessage: "Colchones y cojines",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.mattresses",
      defaultMessage: "Colchones y cojines",
    }),
  },
  {
    id: 16,
    value: intl.formatMessage({
      id: "ApplicationSelect.protection",
      defaultMessage: "Protección",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.protection",
      defaultMessage: "Protección",
    }),
  },
  {
    id: 17,
    value: intl.formatMessage({
      id: "ApplicationSelect.sculpture",
      defaultMessage: "Escultura",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.sculpture",
      defaultMessage: "Escultura",
    }),
  },
  {
    id: 18,
    value: intl.formatMessage({
      id: "ApplicationSelect.yacht",
      defaultMessage: "Yacht",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.yacht",
      defaultMessage: "Yacht",
    }),
  },
  {
    id: 19,
    value: intl.formatMessage({
      id: "ApplicationSelect.other",
      defaultMessage: "Otro",
    }),
    label: intl.formatMessage({
      id: "ApplicationSelect.other",
      defaultMessage: "Otro",
    }),
  },
];
