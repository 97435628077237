import * as Yup from "yup";
import { VALIDATION_MESSAGES } from ".";

/* Yup validation schema for step0 */
export const getValidationShema = (intl) => {
  const validationShema = Yup.object({
    salesRepresentative: Yup.string(),
    invoiceRecipient: Yup.string(),
    projectSpecifier: Yup.string(),
    projectManufacturer: Yup.string(),
    finalClient: Yup.string(),
    soldMeters: Yup.number()
  })

  return validationShema;
}
