import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  getValidationShema,
} from "./constants/validationSchema";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { PLACEHOLDER_MESSAGES } from "./constants";
import "./styles/Step2Form.scss";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "scenes/ProjectMultiStepForm/store";
import { withRouter } from "react-router";
import useCapitalizedLetters from "scenes/ProjectMultiStepForm/hooks/useCapitalizedLetters";

const Step2Form = ({ history }) => {
  const intl = useIntl();
  const { state, actions } = useStateMachine({ updateAction });
  const { capitalizedFields, handleApplyCapitalizedLetters } = useCapitalizedLetters();

  const validationCapitalizedFields = (e, setFieldValue) => {
    const { name, value } = e.target;

    setFieldValue(name, value);
    handleApplyCapitalizedLetters(name, value);

    actions.updateAction({ [name]: value, stepNumber: 2 }); /* INFO: Update the global state before capitalized validation fields */
  }

  const initialValues = {
    salesRepresentative: state?.stepData?.salesRepresentative || "",
    invoiceRecipient: state?.stepData?.invoiceRecipient || "",
    projectSpecifier: state?.stepData?.projectSpecifier || "",
    projectManufacturer: state?.stepData?.projectManufacturer || "",
    finalClient: state?.stepData?.finalClient || "",
    soldMeters: state?.stepData?.soldMeters || ""
  }
  
  const handleSubmit = (stepData) => {
    actions.updateAction({ stepData, stepNumber: 2 });
    /* Go to next step */
    history.push("/project/wizard-form/step3");
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={getValidationShema(intl)}
    >
      {({ setFieldValue, isSubmitting }) => (
        <Form id="step-0-form">
          <div className="step-0-form__container d-flex flex-column">
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="businessUnit"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.salesRepresentative"
                    defaultMessage="Comercial que vendio el proyecto"
                  />
                </label>
                <Field
                  id="salesRepresentative"
                  name="salesRepresentative"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.salesRepresentative}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.salesRepresentative)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="salesRepresentative"
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="area"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.invoiceRecipient"
                    defaultMessage="A quién se le facturó el proyecto"
                  />
                </label>
                <Field
                  id="invoiceRecipient"
                  name="invoiceRecipient"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.invoiceRecipient}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.invoiceRecipient)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="invoiceRecipient"
                />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="businessUnit"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.projectSpecifier"
                    defaultMessage="Quién especificó el proyecto"
                  />
                </label>
                <Field
                  id="projectSpecifier"
                  name="projectSpecifier"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.projectSpecifier}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.projectSpecifier)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="projectSpecifier"
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="area"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.projectManufacturer"
                    defaultMessage="Quién manufacturó el proyecto"
                  />
                </label>
                <Field
                  id="projectManufacturer"
                  name="projectManufacturer"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.projectManufacturer}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.projectManufacturer)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="projectManufacturer"
                />
              </div>
            </div>
            <div className="step-0-form__selects d-lg-flex">
              <div className="col-12 col-lg-6 px-0 mb-3 mb-lg-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="businessUnit"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.finalClient"
                    defaultMessage="Cliente final"
                  />
                </label>
                <Field
                  id="finalClient"
                  name="finalClient"
                  className="form-control bg-transparent"
                  type="text"
                  value={capitalizedFields.finalClient}
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.finalClient)}
                  onChange={(e) => validationCapitalizedFields(e, setFieldValue)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="finalClient"
                />
              </div>
              <div className="col-12 col-6 px-0">
                <label
                  className="step-0-form__label d-block p-0 m-0 mb-lg-1 font-weight-bold"
                  htmlFor="area"
                >
                  <FormattedMessage
                    id="ProjectStepForm.step2.field.soldMeters"
                    defaultMessage="Metros vendidos"
                  />
                </label>
                <Field
                  id="soldMeters"
                  name="soldMeters"
                  className="form-control bg-transparent"
                  type="number"
                  placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.soldMeters)}
                />
                <ErrorMessage
                  className="form-error-message"
                  component="div"
                  name="soldMeters"
                />
              </div>
            </div>
            <button
              type="submit"
              className="step-0-form__submit-btn px-5 py-2 mt-4"
              disabled={isSubmitting}
            >
              <FormattedMessage
                id="ProjectStepForm.button.continue"
                defaultMessage="Continuar"
              />
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(Step2Form);
