import { defineMessages } from "react-intl";

export const VALIDATION_MESSAGES = defineMessages({
  required: {
    id: "ProjectStepForm.field.required",
    description: "Project creation required field message",
    defaultMessage: "This field is required"
  }
})

export const PLACEHOLDER_MESSAGES = {
  fullname: {
    id: "ProjectStepForm.step0.field.completeName",
    description: "Placeholder to field fullname",
    defaultMessage: "Tu nombre y apellido"
  },
  email: {
    id: "ProjectStepForm.step0.field.email",
    description: "Placeholder to field email",
    defaultMessage: "Email"
  },
  businessUnit: {
    id: "ProjectStepForm.placeholder.businessUnit",
    description: "Placeholder to field businessUnit",
    defaultMessage: "Unidad de negocio"
  },
  area: {
    id: "ProjectStepForm.step0.field.area",
    description: "Placeholder to field area",
    defaultMessage: "Area"
  }
}