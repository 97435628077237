/* Placeholder texts of Step 1 */
export const PLACEHOLDER_MESSAGES_STEP1 = {
  projectName: {
    id: "ProjectStepForm.step1.field.projectName",
    description: "Placeholder to field projectName",
    defaultMessage: "Nombre del proyecto"
  },
  products: {
    id: "ProjectStepForm.step1.field.products",
    description: "Placeholder to field products",
    defaultMessage: "Producto(s) usado(s) en el proyecto"
  },
  sector: {
    id: "ProjectStepForm.step1.field.sector",
    description: "Placeholder to field sector",
    defaultMessage: "Sector/Mercado"
  },
  segment: {
    id: "ProjectStepForm.step1.field.segment",
    description: "Placeholder to field segment",
    defaultMessage: "Segmento"
  },
  application: {
    id: "ProjectStepForm.step1.field.finalApplication",
    description: "Placeholder to field application",
    defaultMessage: "Uso final"
  },
  countryProject: {
    id: "ProjectStepForm.step1.field.countryProject",
    description: "Placeholder to field countryProject",
    defaultMessage: "País donde está el proyecto"
  },
  cityProject: {
    id: "ProjectStepForm.step1.field.cityProject",
    description: "Placeholder to field cityProject",
    defaultMessage: "Ciudad donde esta el proyecto"
  },
  implementationYear: {
    id: "ProjectStepForm.step1.field.implementationYear",
    description: "Placeholder to field implementationYear",
    defaultMessage: "Año de implementación del proyecto"
  }
}