import React from 'react'
import { Query } from 'react-apollo';
import { useIntl } from 'react-intl';
import patternsQuery from 'scenes/ProductsPage/components/ProductsList/query';
import { PLACEHOLDER_MESSAGES_STEP1 } from '../../constants';
import MultiSelect from '../MultiSelect';

const ProductsSelect = ({ name, updateField }) => {
  const intl = useIntl();

  return (
    <Query query={patternsQuery}>
      {({ loading, data }) => {
        const { patterns } = data;
        const patternsList = patterns?.edges?.map(({ node }) => {
          return {
            id: node.id,
            value: node.id,
            label: node.name
          }
        });

        return (
          <MultiSelect
            name={name}
            updateField={updateField}
            placeholderMessage={intl.formatMessage(
              PLACEHOLDER_MESSAGES_STEP1["products"]
            )}
            selectValues={patternsList}
            disabled={loading}
          />
        );
      }}
    </Query>
  )
}

export default ProductsSelect;