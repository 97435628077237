/* SECTORS hardcoded values */
export const getSectorValues = (intl) => [
  {
    id: 1,
    label: intl.formatMessage({
      id: "ProjectStepForm.sectors.contract",
      defaultMessage: "Contract",
    }),
    segments: [
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.hospitality",
          defaultMessage: "Hospitality",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.hospitality",
          defaultMessage: "Hospitality",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.healthcare",
          defaultMessage: "Healthcare",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.healthcare",
          defaultMessage: "Healthcare",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.spaces",
          defaultMessage: "Public Spaces",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.spaces",
          defaultMessage: "Public Spaces",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.workplace",
          defaultMessage: "Workplace",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.workplace",
          defaultMessage: "Workplace",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.residential",
          defaultMessage: "Residential",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.residential",
          defaultMessage: "Residential",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.outdoorFurniture",
          defaultMessage: "Outdoor Furniture",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.outdoorFurniture",
          defaultMessage: "Outdoor Furniture",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.education",
          defaultMessage: "Education",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.education",
          defaultMessage: "Education",
        }),
      },
    ],
  },
  {
    id: 2,
    label: intl.formatMessage({
      id: "ProjectStepForm.sectors.marine",
      defaultMessage: "Marine",
    }),
    segments: [
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.outdoor",
          defaultMessage: "Outdoor",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.outdoor",
          defaultMessage: "Outdoor",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.indoor",
          defaultMessage: "Indoor",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.indoor",
          defaultMessage: "Indoor",
        }),
      },
    ],
  },
  {
    id: 3,
    label: intl.formatMessage({
      id: "ProjectStepForm.sectors.footwear",
      defaultMessage: "Footwear",
    }),
    segments: [
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.leathergoods",
          defaultMessage: "Leather goods",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.leathergoods",
          defaultMessage: "Leather goods",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.footwear",
          defaultMessage: "Footwear",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.footwear",
          defaultMessage: "Footwear",
        }),
      },
    ],
  },
  {
    id: 4,
    label: intl.formatMessage({
      id: "ProjectStepForm.sectors.protection",
      defaultMessage: "Protection",
    }),
    segments: [
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.tentsAndAwnings",
          defaultMessage: "Tents and Awnings",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.tentsAndAwnings",
          defaultMessage: "Tents and Awnings",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.protectiveCovers",
          defaultMessage: "Protective covers",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.protectiveCovers",
          defaultMessage: "Protective covers",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.apparel",
          defaultMessage: "Apparel",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.apparel",
          defaultMessage: "Apparel",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.safetyClothing",
          defaultMessage: "Safety Clothing",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.safetyClothing",
          defaultMessage: "Safety Clothing",
        }),
      },
      {
        label: intl.formatMessage({
          id: "ProjectStepForm.sectors.protectiveMats",
          defaultMessage: "Protective mats",
        }),
        value: intl.formatMessage({
          id: "ProjectStepForm.sectors.protectiveMats",
          defaultMessage: "Protective mats",
        }),
      },
    ],
  },
];
