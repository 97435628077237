import React from 'react'
import { LG } from 'scenes/ProductDetails/components/ProductSpecs/components/OtherAttributesAccordion/constants/breakpoints';
import useMediaQuery from 'utils/hooks/useMediaQuery';

const MobileProgress = ({ progressValue }) => {
  const isMobile = useMediaQuery(LG);

  return (
    isMobile && <progress max={100} value={progressValue} className="w-100 position-absolute step--progress_mobile" />
  )
}

export default MobileProgress;