import * as Yup from "yup";
import { VALIDATION_MESSAGES } from ".";

/* Yup validation schema for step0 */
export const getValidationShema = (intl) => {
  const validationShema = Yup.object({
    fullname: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    email: Yup.string().email().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    businessUnit: Yup.string()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    area: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required))
  })

  return validationShema;
}
