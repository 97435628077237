import React from 'react'
import { useIntl } from 'react-intl';
import CustomSelect from 'scenes/ProjectMultiStepForm/components/CustomSelect';
import { PLACEHOLDER_MESSAGES_STEP1 } from '../../constants';
import { getSectorValues } from '../SectorSelect/constants';

const SegmentSelect = ({ name, updateField, sectorValue }) => {
  const intl = useIntl();

  return (
    <CustomSelect
      name={name}
      updateField={updateField}
      placeholderMessage={intl.formatMessage(
        PLACEHOLDER_MESSAGES_STEP1["segment"]
      )}
      selectValues={
        getSectorValues(intl).find((sector) => sector.label === sectorValue)
          ?.segments
      }
      disabled={!sectorValue}
      disabledPlaceholder={intl.formatMessage({ id: "ProjectStepForm.segments.firstSelectSector" })}
    />
  );
}

export default SegmentSelect;