import React from 'react'
import StepInfo from '../../StepInfo'
import { FormattedMessage } from 'react-intl'
import "./styles/Step1.scss";
import StepGuideNumbers from '../../StepGuideNumbers';
import useMediaQuery from 'utils/hooks/useMediaQuery';
import Step1Form from './components/Step1Form';
import StepNavigationBack from '../../StepNavigationBack';

const Step1 = () => {
  const isMobile = useMediaQuery("(max-width: 992px)");
  const progressStepValue = 50;
  const currentSteps = [0, 1];

  return (
    <main title="step-1-form" className="step0--main">
      <StepInfo
        subtitle={
          <FormattedMessage 
            id="ProjectStepForm.step1.subtitle"
            description="Subtitle of Step form 1"
            defaultMessage="Formulario | Biblioteca de Proyectos"
          />
        }
      />
      {isMobile && (
        <StepGuideNumbers
          currentStep={currentSteps}
          progressStepValue={progressStepValue}
          isMobile={isMobile}
        />
      )}
      <section title="section-wizard-form" className="step0--main__wizard-form">
        {!isMobile && <StepGuideNumbers currentStep={currentSteps} />}
        <div className='step0--main__wizard-container border bg-light col-12 col-lg-8 p-4 px-lg-5'>
          <progress max={100} value={progressStepValue} className="w-100" />
          <StepNavigationBack />
          <h5 className="step0--main__form-title mt-2 font-weight-bold">
            <FormattedMessage
              id="ProjectStepForm.step1.title"
              defaultMessage="Datos del proyecto"
            />
          </h5>
          <hr />
          <p className='step0--main__form-description font-weight-bold'>
            <FormattedMessage 
              id="ProjectStepForm.step1.description"
              description="Step 1 form description"
              defaultMessage='A continuación, debes diligenciar todos los datos del proyecto'
            />
          </p>
          <Step1Form />
        </div>
      </section>
    </main>
  );
}

export default Step1;