import React from 'react'
import spradlingFormLogo from "./img/spradling-logo.svg";
import { FormattedMessage } from 'react-intl';
import "./styles/StepInfo.scss";

const StepInfo = ({ subtitle, additionalInfo, description }) => {
  return (
    <main title='step-info-header' className="container text-center pt-4">
      <div className="py-2">
        <img
          src={spradlingFormLogo}
          alt="spradling-logo"
          title="Spradling logo"
        />
      </div>
      <h4 className="step-info__subtitle py-2 pt-lg-2 font-weight-bold m-0">{subtitle}</h4>
      {additionalInfo && <p className="step-info__text text-secondary col-12 px-5 px-lg-0 pb-3 m-0">{additionalInfo}</p>}
      <p className="step-info__text p-0 m-0 text-secondary">
        <FormattedMessage
          id="ProjectStepForm.step0.disclaimer"
          defaultMessage="Ten en cuenta que los campos con (*) son obligatorios."
        />
      </p>
      {description && <p className="step-info__text text-secondary p-0 m-0">{description}</p>}
    </main>
  );
}

export default StepInfo