import React, { useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl';
import { PLACEHOLDER_MESSAGES } from '../../constants';
import TooltipField from 'scenes/ProjectMultiStepForm/components/TooltipField';
import { ErrorMessage, useFormikContext } from 'formik';
import trashIcon from "./img/trash-icon.svg";
import "./styles/CustomFileInput.scss";
import useMediaQuery from 'utils/hooks/useMediaQuery';
import closeIcon from "../../../../../Step1/components/Step1Form/components/MultiSelect/components/MultiSelectOption/img/close.png";

const CustomFileInput = () => {
  const intl = useIntl()
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery("(max-width: 992px)");

  const { 
    values: { authorizationProject, authorizationSupport }, setFieldValue 
  } = useFormikContext();
  const convertedAuthorizationProject = 
    authorizationProject === "Si" || authorizationProject === "Yes" ? true : false;

  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];
    setFieldValue("authorizationSupport", file);
  };
  
  const handleDeleteSelectedFile = () => {
    setFieldValue("authorizationSupport", null);
  }

  const triggerFileInput = () => {
    document.getElementById('authorizationSupport').click();
  };

  return (
    convertedAuthorizationProject && (
      <div className="step-0-form__selects d-lg-flex">
        <div className="col-12 col-lg-12 px-0 mb-3 mb-lg-0">
          <label
            className="step-0-form__label p-0 m-0 mb-lg-1 font-weight-bold"
            htmlFor="authorizationSupport"
          >
            <FormattedMessage
              id="ProjectStepForm.step3.field.authorizationSupport"
              defaultMessage="Carta, imagen, contrato o soporte de autorización de uso"
            />
            {"*"}
          </label>
          <div className="form-control bg-transparent d-flex justify-content-between align-items-center">
            <span className="custom-file-input__file-name">
              {authorizationSupport ? (
                authorizationSupport.name
              ) : (
                <FormattedMessage
                  id="ProjectStepForm.step3.field.authorizationSupport.placeholder"
                  defaultMessage="Comprobante de autorización de uso"
                />
              )}
              {authorizationSupport && (
                <img
                  className="ml-2"
                  src={closeIcon}
                  alt="close-icon"
                  width={9}
                  height={9}
                  onClick={handleDeleteSelectedFile}
                />
              )}
            </span>
            <button
              className="custom-file-input__button-file border-0 px-3 rounded"
              type="button"
              onClick={triggerFileInput}
            >
              <img src={trashIcon} alt="trash-icon" width={18} height={18} />
              {!isMobile && (
                <p className="p-0 m-0 d-inline ml-2">
                  <FormattedMessage
                    id="ProjectStepForm.step3.field.selectFileBtn"
                    defaultMessage="Examinar..."
                  />
                </p>
              )}
            </button>
          </div>
          <div className="custom-file-input__info col-12 text-center">
            <FormattedMessage
              id="ProjectStepForm.step3.field.info"
              defaultMessage=" Sube tu comprobante de autorización en formato JPEG, DOCX o PDF (máx. 2 MB)"
            />
          </div>
          <input
            ref={fileInputRef}
            id="authorizationSupport"
            name="authorizationSupport"
            type="file"
            className="d-none"
            placeholder={intl.formatMessage(
              PLACEHOLDER_MESSAGES.authorizationSupport
            )}
            onChange={handleFileChange}
          />
          <ErrorMessage
            className="form-error-message"
            component="div"
            name="authorizationSupport"
          />
        </div>
      </div>
    )
  );
}

export default CustomFileInput;
