import React from 'react'
import { useIntl } from 'react-intl';
import { PLACEHOLDER_MESSAGES_STEP1 } from '../../constants';
import CustomSelect from 'scenes/ProjectMultiStepForm/components/CustomSelect';
import { getSectorValues } from './constants';

const SectorSelect = ({ name, updateField }) => {
  const intl = useIntl();

  return (
    <CustomSelect
      name={name}
      updateField={updateField}
      placeholderMessage={intl.formatMessage(
        PLACEHOLDER_MESSAGES_STEP1["sector"]
      )}
      selectValues={getSectorValues(intl)}
    />
  );
}

export default SectorSelect;