import * as Yup from "yup";
import { VALIDATION_MESSAGES } from ".";

/* Yup validation schema for step3 */
export const getValidationShema = (intl) => {
  const validationShema = Yup.object({
    authorizationImages: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    authorizationProject: Yup.string().required(intl.formatMessage(VALIDATION_MESSAGES.required)),
    authorizationSupport: Yup.mixed().when("authorizationProject", {
      is: "Si",
      then: () => Yup.mixed()
      .required(intl.formatMessage(VALIDATION_MESSAGES.required))
      .test("fileSize", "El archivo es demasiado grande", (value) => {
        return value && value.size <= 2000000; // Example: 2MB as limit
      })
      .test("fileType", "El tipo de archivo no es válido", (value) => {
        const allowedTypes = ["image/jpeg", "application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"]; /* Allow file types as PDF, JPEG and DOCX */

        return value && allowedTypes.includes(value.type);
      }),
      otherwise: Yup.mixed().notRequired(),
    }),
    credits: Yup.string(),
    bussinessUnitPreviewAuthorization: Yup.string(),
  })

  return validationShema;
}
