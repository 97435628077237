import React from "react";
import { FormattedMessage } from "react-intl"

/* AUTHORIZATION_IMAGES_VALUES select values */
export const AUTHORIZATION_IMAGES_VALUES = [
  {
    id: 1,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step3.field.requiredCredits.authorizationImages"
        defaultMessage="Uso libre, pero requiere créditos"
      />
    ),
    value: "Uso libre, pero requiere créditos"
  },
  {
    id: 2,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step3.field.notRequiredCredits.authorizationImages"
        defaultMessage="Uso libre, sin necesidad de créditos"
      />
    ),
    value: "Uso libre, sin necesidad de créditos",
    color: "text-success"
  },
  {
    id: 3,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step3.field.restringedUse.authorizationSupport"
        defaultMessage="Uso restringido. Se requiere autorización"
      />
    ),
    value: "Uso restringido. Se requiere autorización",
    color: "text-danger"
  },
]

/* Area select Values */
export const AUTHORIZATION_PROJECT_VALUES = [
  {
    id: 1,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step3.field.yes.authorizationProject"
        defaultMessage="Si"
      />
    ),
    value: true,
  },
  {
    id: 2,
    label: (
      <FormattedMessage 
        id="ProjectStepForm.step3.field.no.authorizationProject"
        defaultMessage="No"
      />
    ),
    value: false,
  }
]