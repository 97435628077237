import React, { useCallback, useState } from 'react'

const useCapitalizedLetters = () => {
  const [capitalizedFields, setCapitalizedFields] = useState({});

  const applyCapitalizedLetters = useCallback((value) => {
    const splitValue = value.split(" ");
    const linkWords = ["de", "del", "la", "las", "los", "Y", "el", "of"];

    const capitalizedValue = splitValue
      .map((word, index) => {
        if (linkWords.includes(word.toLowerCase()) && index !== 0) {
          return word.toLowerCase();
        }

        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");

    return capitalizedValue;
  }, []);

  const handleApplyCapitalizedLetters = (name, value) => {
    const processedValue = applyCapitalizedLetters(value);
    setCapitalizedFields({ ...capitalizedFields, [name]: processedValue });
  }
  
  return {
    capitalizedFields,
    handleApplyCapitalizedLetters
  }
}

export default useCapitalizedLetters
