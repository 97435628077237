import classNames from 'classnames';
import React, { Fragment, useMemo, useState } from 'react'
import { Tooltip } from 'reactstrap';
import infoIcon from "./img/info-icon.svg";
import "./styles/TooltipField.scss";

let idCounter = 0;

const TooltipField = ({ tooltipMessage }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipId = useMemo(
    () => `expiredDataTooltip_sustainability_${idCounter++}`,
    []
  );

  const toggle = (value) => setTooltipOpen(value);

  return (
    <Fragment>
      <span
        className="field-stepform-tooltip ml-1 position-relative"
        tabIndex="0"
        id={tooltipId}
        onMouseOver={() => toggle(true)}
        onMouseLeave={() => toggle(false)}
      >
        <span>
          <img src={infoIcon} alt='info-icon-tooltip' width={13} height={13} />
        </span>
        <Tooltip
          key={tooltipId}
          placement={"top"}
          isOpen={tooltipOpen}
          target={tooltipId}
          className={classNames({
            "expiration-certificate--tooltip-message": false,
            "expiration-certificate--tooltip-message-mobile-sustainability":
              true,
          })}
        >
          {tooltipMessage}
        </Tooltip>
      </span>
    </Fragment>
  );
}

export default TooltipField;
